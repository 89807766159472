import React from 'react';

import Page from '../components/page';
import { IS_BROWSER } from '../utils/constants';
// import Event from '../components/event';

export const UnpublishedPage = props => {
  if (!IS_BROWSER) {
    return null;
  }

  // const previewData = window.__PRISMIC_PREVIEW_DATA__;

  // if (previewData.prismicEvent) {
  //   return <Event {...props} />;
  // }

  return <Page {...props} />;
};

export default UnpublishedPage;

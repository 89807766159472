import React from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';

import Layout from '../components/layout';
import SEO from '../components/seo';
import SliceZone from './slice-zone';
import { IS_BROWSER } from '../utils/constants';

function Page({ data: staticData }) {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;

  const data = mergePrismicPreviewData({ staticData, previewData });

  const {
    prismicPage: {
      data: {
        page_title: title,
        seo_title,
        seo_description,
        body,
        menu_background,
        header_items_color,
        logo,
        menu_logo,
        display_review_bar,
        mobile_logo,
        mobile_header_items_color,
        fixed_background_transparent_mode,
        fixed_background_color,
        fixed_menu_items_color,
        fixed_mobile_header_items_color,
        fixed_menu_logo,
        footer_logo,
        fixed_mobile_logo,
        header_background_color,
        ...rest
      },
    },
  } = data;

  return (
    <Layout
      menu_background={menu_background}
      header_items_color={header_items_color}
      fixed_mobile_header_items_color={fixed_mobile_header_items_color}
      display_review_bar={display_review_bar}
      logo={logo}
      menu_logo={menu_logo}
      mobile_logo={mobile_logo}
      header_background_color={header_background_color}
      mobile_header_items_color={mobile_header_items_color}
      fixed_background_transparent_mode={fixed_background_transparent_mode}
      fixed_background_color={fixed_background_color}
      fixed_menu_items_color={fixed_menu_items_color}
      fixed_mobile_logo={fixed_mobile_logo}
      fixed_menu_logo={fixed_menu_logo}
      footer_logo={footer_logo}
    >
      <SEO
        title={seo_title && seo_title.text}
        description={seo_description && seo_description.text}
      />
      <SliceZone body={body} customData={data} />
    </Layout>
  );
}

export const query = graphql`
  query PageQuery($id: String) {
    prismicPage(id: { eq: $id }) {
      id
      uid
      type
      data {
        page_title {
          text
        }
        url_parts {
          url_part {
            text
          }
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
        menu_background
        logo
        header_items_color
        display_review_bar
        menu_logo
        mobile_logo
        fixed_mobile_logo
        mobile_header_items_color
        fixed_mobile_header_items_color
        header_background_color
        fixed_background_transparent_mode
        fixed_background_color
        fixed_menu_items_color
        fixed_menu_logo
        footer_logo
        body {
          ... on PrismicPageBodyBlankSpace {
            ...BlankSpace
          }
          ... on PrismicPageBodyExpertiseHeader {
            ...ExpertiseHeader
          }
          ... on PrismicPageBodyWhatWeDo {
            ...WhatWeDo
          }
          ... on PrismicPageBodyLeftTextRightImage {
            ...LeftTextRightImage
          }
          ... on PrismicPageBodyCaseStudyFullWidthSplash {
            ...CaseStudyFullWidthSplash
          }
          ... on PrismicPageBodyAwardsBlock {
            ...AwardsBlock
          }
          ... on PrismicPageBodyFooterPreface {
            ...FooterPreface
          }
          ... on PrismicPageBodyCustomHtml {
            ...CustomHtml
          }
          ... on PrismicPageBodySubPageHeader {
            ...SubPageHeader
          }
          ... on PrismicPageBodyReview {
            ...Review
          }
          ... on PrismicPageBodyRightTextLeftImage {
            ...RightTextLeftImage
          }
          ... on PrismicPageBodyBackgroundLeftTextRightImage {
            ...BackgroundLeftTextRightImage
          }
          ... on PrismicPageBodyProccessBlock {
            ...ProcessBlock
          }
          ... on PrismicPageBodyRectangleSplashBlock {
            ...RectangleSplashBlock
          }
          ... on PrismicPageBodyFaqs {
            ...Faq
          }
          ... on PrismicPageBodyCaseStudySplash {
            ...CaseStudySplash
          }
          ... on PrismicPageBodyStatsBlock {
            ...StatsBlock
          }
          ... on PrismicPageBodyGradientStatBlock {
            ...GradientStatBlock
          }
          ... on PrismicPageBodyLeftSubtitleRightText {
            ...LeftSubtitleRightText
          }
          ... on PrismicPageBodyRightLinksLeftSubtitle {
            ...RightLinksLeftSubtitlePrismic
          }
          ... on PrismicPageBodyTeamBlock {
            ...TeamBlock
          }
          ... on PrismicPageBodyCtaCard {
            ...CtaCard
          }
          ... on PrismicPageBodyTextCta {
            ...TextCta
          }
          ... on PrismicPageBodyExpertiseHeader1 {
            ...ExpertiseHeaderTwo
          }
          ... on PrismicPageBodyServicesListExpanded {
            ...ServicesListExpanded
          }
          ... on PrismicPageBodyCaseStudiesSlider {
            ...CaseStudiesSlider
          }
          ... on PrismicPageBodyAnchor {
            ...Anchor
          }
          ... on PrismicPageBodyLeftText {
            ...LeftText
          }
          ... on PrismicPageBodyRightText {
            ...RightText
          }
          ... on PrismicPageBodyHomepageHeader {
            ...HomepageHeader
          }
          ... on PrismicPageBodyRightTwoImageLeftText {
            ...RightTwoImageLeftText
          }
          ... on PrismicPageBodyRightTwoTextLeftImage {
            ...RightTwoTextLeftImage
          }
          ... on PrismicPageBodyClientList {
            ...ClientList
          }
          ... on PrismicPageBodySmallerServicesList {
            ...SmallerServicesList
          }
          ... on PrismicPageBodyCaseStudyHeader1 {
            ...CaseStudyHeaderOne
          }
          ... on PrismicPageBodyProjectBrief {
            ...ProjectBrief
          }
          ... on PrismicPageBodyTypingText {
            ...TypingText
          }
          ... on PrismicPageBodyContactForm {
            ...ContactForm
          }
          ... on PrismicPageBodyOurGrowthTeam {
            ...OurGrowthTeam
          }
          ... on PrismicPageBodyWhatHappensNext {
            ...WhatHappensNext
          }
          ... on PrismicPageBodyRecognitionSplash {
            ...RecognitionSplash
          }
          ... on PrismicPageBodyReview2 {
            ...ReviewTwo
          }
          ... on PrismicPageBodyGoogleReviewSlider {
            ...GoogleReviewSlider
          }
          ... on PrismicPageBodyLargeReviewSlider {
            ...LargeReviewSlider
          }
          ... on PrismicPageBodyReviewSlider {
            ...ReviewSlider
          }
          ... on PrismicPageBodyCaseStudyHeader2 {
            ...CaseStudyHeaderTwo
          }
          ... on PrismicPageBodyMeetTheTeam {
            ...MeetTheTeam
          }
          ... on PrismicPageBodyVacancies {
            ...Vacancies
          }
          ... on PrismicPageBodyFlexibleCards {
            ...FlexibleCards
          }
          ... on PrismicPageBodyClientsBlock {
            ...ClientsBlock
          }
          ... on PrismicPageBodySocialImagesBlock {
            ...SocialImagesBlock
          }
          ... on PrismicPageBodyTwoImageHeader {
            ...TwoImageHeader
          }
          ... on PrismicPageBodyProposition {
            ...Proposition
          }
          ... on PrismicPageBodyStandOutBlock {
            ...StandOutBlock
          }
          ... on PrismicPageBodyMeetOurTeamSplash {
            ...MeetOurTeamSplash
          }
          ... on PrismicPageBodyAwardsNoTitle {
            ...AwardsNoTitle
          }
          ... on PrismicPageBodyAwardIcons {
            ...AwardIcons
          }
          ... on PrismicPageBodyStatsBlockReversed {
            ...StatsBlockReversed
          }
          ... on PrismicPageBodyImageGallery {
            ...ImageGallery
          }
          ... on PrismicPageBodyFullWidthImageHeader {
            ...FullWidthImageHeader
          }
          ... on PrismicPageBodyVideoShowcase {
            ...VideoShowcase
          }
          ... on PrismicPageBodyProcess {
            ...OurProcess
          }
          ... on PrismicPageBodyStandardText {
            ...StandardText
          }
          ... on PrismicPageBodyDynamicCaseStudyOne {
            ...DynamicCaseStudyOne
          }
        }
      }
    }
  }
`;

export default Page;
